<template>
    <div>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"
                      v-on:success-create-estimate="successCreateEstimate"
                      v-on:success-create-order="successCreateOrder"
                      v-on:success-create-quote="successCreateQuote"
                      v-on:success-create-project="successCreateProject"
                      v-on:success-delete-document="successDeleteDocument"
                      v-on:success-update-customer="successUpdateCustomer"
                      v-on:success-update-project="successUpdateProject"/>

        <div class="card st-framework-card" v-if="pageLoaded">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 260px;">
                            <font-awesome-icon class="mr-2" :icon="icon" size="sm"/>
                            {{ $i18n.tc('translations.' + name, 1).toUpperCase() }}
                        </td>
                        <td style="width: 1rem;"></td>
                        <td style="margin: 0; padding: 0 10px; border: solid 1px lightgray;">
                            <SelectField :field="fields.selectField" v-on:input="projectSelectInput"/>
                        </td>
                        <td style="margin: 0; padding: 0; border: solid 1px lightgray; text-align: center; width: 38px;"
                            v-if="showCreateButton">
                            <template v-for="(action,index) in actions.manageActions">
                                <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                              v-on:create-project="createProject"
                                              v-if="action.type === 'BUTTON' && action.placement === 'DOCUMENTHEADERRIGHT'"/>
                            </template>
                        </td>
                        <td style="margin: 0; padding: 0; width: 90px; text-align: right;">(#{{ data.id }})</td>
                        <td style="width: 1rem;"></td>
                    </tr>
                </table>
            </div>

            <div class="card-body st-fadein-content p-0">
                <div class="row no-gutters">
                    <div class="col-6">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Project', 1) }} ({{ data.project_status_id.label }})
                                    <template v-for="(action,index) in actions.manageActions">
                                        <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                      v-on:update-project="updateProject"
                                                      v-if="action.type === 'BUTTON' && action.placement === 'PROJECTCARDHEADER'"/>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Number') }}</b></td>
                                        <td class="p-0">{{ data.id }}</td>
                                    </tr>
                                    <tr>
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.name_translation }}</td>
                                    </tr>
                                    <tr v-if="data.reference">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Reference') }}</b></td>
                                        <td class="p-0">{{ data.reference }}</td>
                                    </tr>
                                    <tr v-if="data.manager">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Manager') }}</b></td>
                                        <td class="p-0">{{ data.manager }}</td>
                                    </tr>
                                    <tr v-if="data.correspondent">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Correspondent') }}</b></td>
                                        <td class="p-0">{{ data.correspondent }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer light" v-if="sketchUpBrowser && sketchUpToolData">
                                <b-form inline>
                                    <template v-for="(action,index) in actions.manageActions">
                                        <ToggleAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled" :isOn="sketchUpToolData.project_id === data.id"
                                                      v-on:toggle-action="toggleSketchUpProject"
                                                      v-if="action.type === 'TOGGLE' && action.placement === 'PROJECTCARDFOOTER'"/>
                                    </template>
                                </b-form>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card mb-0">
                            <div class="card-header">
                                <b-form inline>
                                    {{ $i18n.tc('translations.Customer', 1) }}
                                    <template v-if="!state.readonly">
                                        <template v-for="(action,index) in actions.manageActions">
                                            <ButtonAction :key="action.name + '-' + index" :action="action" :disabled="state.disabled"
                                                          v-on:update-customer="updateCustomer"
                                                          v-if="action.type === 'BUTTON' && action.placement === 'CUSTOMERCARDHEADER'"/>
                                        </template>
                                    </template>
                                </b-form>
                            </div>
                            <div class="card-body">
                                <table class="table table-borderless table-sm" style="margin-bottom: 0">
                                    <tbody>
                                    <tr v-if="data.customer_id.full_name">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Name') }}</b></td>
                                        <td class="p-0">{{ data.customer_id.full_name }}</td>
                                    </tr>
                                    <tr v-if="data.customer_id.company">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.Company') }}</b></td>
                                        <td class="p-0">{{ data.customer_id.company }}</td>
                                    </tr>
                                    <tr v-if="data.customer_id.street || data.customer_id.city || data.customer_id.country">
                                        <td class="p-0" style="width: 120px; vertical-align: top;"><b>{{ $i18n.t('translations.Address') }}</b></td>
                                        <td class="p-0">
                                            <span v-if="data.customer_id.street">{{ data.customer_id.street }} {{ data.customer_id.number }}<br></span>
                                            <span v-if="data.customer_id.city">{{ data.customer_id.postal }} {{ data.customer_id.city }}<br></span>
                                            <span v-if="data.customer_id.country">{{ data.customer_id.country }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="data.customer_id.vat">
                                        <td class="p-0" style="width: 120px;"><b>{{ $i18n.t('translations.VAT') }}</b></td>
                                        <td class="p-0">{{ data.customer_id.vat }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Table header-class="light" footer-class="light"
                       :title="$i18n.tc('translations.Document', 2)" :state="state"
                       :actions="actions.documentRowActions.concat(actions.documentActions)"
                       :fields="fields.documentFields" :rows="documents.rows"
                       :total-fields="fields.documentTotalFields" :totals="documents.totals"
                       v-on:create-estimate="createEstimate"
                       v-on:create-order="createOrder"
                       v-on:create-quote="createQuote"
                       v-on:delete-row="deleteDocument"
                       v-on:inline-edit="inlineEdit"/>

                <Table header-class="light" footer-class="light" :state="state"
                       :title="$i18n.tc('translations.Archived', 1)"
                       :actions="actions.documentRowActions" :fields="fields.documentFields"
                       :rows="archived_documents.rows"
                       v-on:create-estimate="createEstimate"
                       v-on:create-order="createOrder"
                       v-on:create-quote="createQuote"
                       v-on:delete-row="deleteDocument"
                       v-on:inline-edit="inlineEdit"
                       v-if="archived_documents.rows && archived_documents.rows.length"/>
            </div>

        </div>

    </div>
</template>

<script>
import ButtonAction from "@/components/actions/ButtonAction";
import RecordDialog from "@/components/dialogs/RecordDialog";
import SelectField from "@/components/forms/fields/SelectField.vue";
import Table from "@/components/Table";
import ToggleAction from "@/components/actions/ToggleAction";

/* global sketchup:false */

export default {
    name: "Project",
    components: {
        ButtonAction,
        RecordDialog,
        SelectField,
        Table,
        ToggleAction,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages'],
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            sketchUpToolData: null,
            showCreateButton: false,
            actions: null,
            fields: null,
            data: null,
            documents: null,
            archived_documents: null,
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                ok_event: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
        }
    },
    watch: {
        $route() {
            this.fetchData(parseInt(this.$route.params.id));
        }
    },
    created() {
        if (this.sketchUpBrowser) {
            window.vm.Tool = this;
            sketchup.getToolData();
        }
        this.fetchData(parseInt(this.$route.params.id));
    },
    methods: {
        checkLayout() {
            this.showCreateButton = false;
            this.actions.manageActions.forEach(action => {
                if (action.placement === 'DOCUMENTHEADERRIGHT') {
                    this.showCreateButton = true;
                }
            });
        },
        createEstimate() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Estimate", 1);
            this.recordDialogParameters.api = '/estimates';
            this.recordDialogParameters.fields = this.fields.estimateFields;
            this.recordDialogParameters.data = {
                'name': this.data.name_translation,
                'project_id': this.data,
            };
            this.recordDialogParameters.ok_event = 'success-create-estimate';
            this.showRecordDialog = true;
        },
        createOrder() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Order", 1);
            this.recordDialogParameters.api = '/orders';
            this.recordDialogParameters.fields = this.fields.orderFields;
            this.recordDialogParameters.data = {
                'name': this.data.name_translation,
                'project_id': this.data,
            };
            this.recordDialogParameters.ok_event = 'success-create-order';
            this.showRecordDialog = true;
        },
        createProject() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Project", 1);
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.projectFields;
            this.recordDialogParameters.data = null;
            this.recordDialogParameters.ok_event = 'success-create-project';
            this.showRecordDialog = true;
        },
        createQuote() {
            this.recordDialogParameters.type = "create";
            this.recordDialogParameters.title = this.$i18n.tc("translations.Quote", 1);
            this.recordDialogParameters.api = '/quotes';
            this.recordDialogParameters.fields = this.fields.quoteFields;
            this.recordDialogParameters.data = {
                'name': this.data.name_translation,
                'project_id': this.data,
            };
            this.recordDialogParameters.ok_event = 'success-create-quote';
            this.showRecordDialog = true;
        },
        deleteDocument(data) {
            this.recordDialogParameters.type = "delete";
            this.recordDialogParameters.title = data.name;
            switch (data.document) {
                case this.$i18n.tc("translations.Estimate", 1):
                    this.recordDialogParameters.api = '/estimates';
                    break;
                case this.$i18n.tc("translations.Order", 1):
                    this.recordDialogParameters.api = '/orders';
                    break;
                case this.$i18n.tc("translations.Quote", 1):
                    this.recordDialogParameters.api = '/quotes';
                    break;
            }
            this.recordDialogParameters.fields = this.fields.documentFields;
            this.recordDialogParameters.data = data;
            this.recordDialogParameters.ok_event = 'success-delete-document';
            this.showRecordDialog = true;
        },
        fetchData(id) {
            this.state.loading = true;
            this.pageLoaded = false;
            this.$http.get(this.api + '/manage/' + id, {}).then((res) => {
                this.setData(res.data);
                this.pageLoaded = !!res.data.data;
                this.state.loading = false;
            }).catch((error) => {
                this.data = null;
                console.log("Project:fetchData():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.sketchUpToolData = data;
        },
        inlineEdit() {
            this.fetchData(this.data.id);
        },
        projectSelectInput(data) {
            if (data['id'] !== parseInt(this.$route.params.id)) {
                this.$router.push({params: {id: data['id']}});
            }
        },
        setData(data) {
            this.data = data.data;
            this.actions = data.actions;
            this.fields = data.fields;
            this.documents = data.documents;
            this.archived_documents = data.archived_documents;
            this.checkLayout();
        },
        setToolDataCallback(data) {
            this.sketchUpToolData = data;
            this.state.loading = false;
        },
        successCreateEstimate(data) {
            this.$router.push({name: 'estimates', params: {id: data.data.id}});
        },
        successCreateOrder(data) {
            this.$router.push({name: 'orders', params: {id: data.data.id}});
        },
        successCreateProject(data) {
            this.setData(data);
        },
        successCreateQuote(data) {
            this.$router.push({name: 'quotes', params: {id: data.data.id}});
        },
        successDeleteDocument() {
            this.fetchData(this.data.id);
        },
        successUpdateCustomer(data) {
            this.data.customer_id = data.data;
        },
        successUpdateProject(data) {
            this.setData(data);
        },
        toggleSketchUpProject() {
            this.state.loading = true;
            if (this.sketchUpToolData.project_id === this.data.id) {
                sketchup.setToolData(0, 0, 0);
            } else {
                sketchup.setToolData(this.data.id, 0, 0);
            }
        },
        updateCustomer() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.customer_id.label;
            this.recordDialogParameters.api = '/contacts';
            this.recordDialogParameters.fields = this.fields.customerFields;
            this.recordDialogParameters.data = this.data.customer_id;
            this.recordDialogParameters.ok_event = 'success-update-customer';
            this.showRecordDialog = true;
        },
        updateProject() {
            this.recordDialogParameters.type = "update";
            this.recordDialogParameters.title = this.data.label;
            this.recordDialogParameters.api = this.api;
            this.recordDialogParameters.fields = this.fields.projectFields;
            this.recordDialogParameters.data = this.data;
            this.recordDialogParameters.ok_event = 'success-update-project';
            this.showRecordDialog = true;
        },
    }
}
</script>

<style scoped>
</style>
